import axios from 'util/Api'


function getSemanticUnitMap (semanticId){
  return axios.get(`admin/SemanticUnitMap?semanticId=${semanticId}`)
}
function addSemanticUnitMap (body){
  return axios.post(`admin/SemanticUnitMap`, body)
}
function editSemanticUnitMap (body){
  return axios.put(`admin/SemanticUnitMap`, body)
}
function deleteSemanticUnitMap (id){
  return axios.delete(`admin/SemanticUnitMap/${id}`)
}

export {getSemanticUnitMap, addSemanticUnitMap, editSemanticUnitMap, deleteSemanticUnitMap}