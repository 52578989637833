export * from './semantic';
export * from './facilityType';
export * from './semanticUnits';
export * from './users';
export * from './profile';
export * from './licenses';
export * from './facility';
export * from './superAdmin';
export * from './endNodeLibrary';
export * from './endNodeLibrarySpecification';
export * from './rawMessage';
export * from './insightLibrary';
export * from './insightLibraryRequirement';
export * from './semanticUnitsMap';
export * from './FileIcons';
export * from './backnetObjectType';
export * from './reservedName';
export * from './hubs';
export * from './endnodes';



export const BASE_IMG = "//api.twinup.ai/api/user/FileManager?fileName=";

 