import React, {useEffect} from "react";
import {Button, Form, Input} from "antd";
import {useDispatch, useSelector} from "react-redux";
// import {Link} from "react-router-dom";

import {userSignIn, userSignInActive} from "../appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";

const FormItem = Form.Item;

const SignIn = (props) => {
  const dispatch = useDispatch();
  const token = useSelector(({auth}) => auth.token);
  const [openActiveCode, setOpenActiveCode] = React.useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        dispatch(userSignIn(values));
      }
    });
  };
  const handleSubmitActiveCode = (e) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        dispatch(userSignInActive({
          token: token.code,
          code: parseInt(values.code)
        }));
      }
    });
  };

  useEffect(() => {
    if (token && token.code) {      
      setOpenActiveCode(true);
    }
    if(token && !token.code){
      props.history.push('/');
    }
  }, [token, props.history]);

  const {getFieldDecorator} = props.form;

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              {/* <img src="https://via.placeholder.com/272x395" alt='Neature'/> */}
            </div>
            <div className="gx-app-logo-wid">
              <h1><IntlMessages id="app.userAuth.signIn"/></h1>
              {/* <p><IntlMessages id="app.userAuth.bySigning"/></p>
              <p><IntlMessages id="app.userAuth.getAccount"/></p> */}
            </div>
            <div className="gx-app-logo">
              <img alt="example" src={require("assets/images/logo.png")}/>
            </div>
          </div>
          <div className="gx-app-login-content">
            {
              openActiveCode ? 
              <Form onSubmit={handleSubmitActiveCode} className="gx-signin-form gx-form-row0">
                <FormItem>
                  {getFieldDecorator('code', {                  
                    rules: [{
                      required: true, message: 'Please enter active code !',
                    }],
                  })(
                    <Input placeholder="Code"/>
                  )}
                </FormItem>                              
                <FormItem>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.signIn"/>
                  </Button>
                </FormItem>
              </Form>
              :
              <Form onSubmit={handleSubmit} className="gx-signin-form gx-form-row0">

              <FormItem>
                {getFieldDecorator('email', {
                  rules: [{
                    required: true, type: 'email', message: 'The input is not valid E-mail!',
                  }],
                })(
                  <Input placeholder="Email"/>
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('password', {
                  rules: [{required: true, message: 'Please input your Password!'}],
                })(
                  <Input type="password" placeholder="Password"/>
                )}
              </FormItem>
             
              <FormItem>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.signIn"/>
                </Button>
              </FormItem>
            </Form>
            } 
            
          </div>
          <InfoView/>
        </div>
      </div>
    </div>
  );
};

const WrappedNormalLoginForm = Form.create()(SignIn);

export default WrappedNormalLoginForm;
