import axios from 'util/Api'


function getInsightLibrary (pageNumber, pageSize){
  return axios.get(`admin/InsightLibrary?pageNumber=${pageNumber}&pageSize=${pageSize}`)
}
function addInsightLibrary (body){
  return axios.post(`admin/InsightLibrary`, body)
}
function editInsightLibrary (body){
  return axios.put(`admin/InsightLibrary`, body)
}
function deleteInsightLibrary (id){
  return axios.delete(`admin/InsightLibrary/${id}`)
}

export {getInsightLibrary, addInsightLibrary, editInsightLibrary, deleteInsightLibrary}