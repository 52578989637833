import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./Dashboard'))}/>
      <Route path={`${match.url}specification`} component={asyncComponent(() => import('./Specification'))}/>
      <Route path={`${match.url}semantics`} component={asyncComponent(() => import('./Semantics'))}/>
      <Route path={`${match.url}facilityTypes`} component={asyncComponent(() => import('./FacilityTypes'))}/>
      <Route path={`${match.url}semanticUnits`} component={asyncComponent(() => import('./SemanticUnits'))}/>
      <Route path={`${match.url}users`} component={asyncComponent(() => import('./Users'))}/>
      <Route path={`${match.url}superadmin`} component={asyncComponent(() => import('./SuperAdmin'))}/>
      <Route path={`${match.url}profile`} component={asyncComponent(() => import('./Profile'))}/>
      <Route path={`${match.url}license`} component={asyncComponent(() => import('./License'))}/>
      <Route path={`${match.url}facilities`} component={asyncComponent(() => import('./Facilities'))}/>
      <Route path={`${match.url}rawmessage`} component={asyncComponent(() => import('./RawMessage'))}/>
      <Route path={`${match.url}insights`} component={asyncComponent(() => import('./Insights'))}/>
      <Route path={`${match.url}icons`} component={asyncComponent(() => import('./Icons'))}/>
      <Route path={`${match.url}reservedname`} component={asyncComponent(() => import('./ReservedName'))}/>
      <Route path={`${match.url}bacnetobjecttype`} component={asyncComponent(() => import('./BacnetObjectType'))}/>
      <Route path={`${match.url}hubs`} component={asyncComponent(() => import('./Hubs'))}/>
      <Route path={`${match.url}endnodes`} component={asyncComponent(() => import('./Endnodes'))}/>


    </Switch>
    
  </div>
);

export default App;
