import axios from 'util/Api'


function getUsersAdmin (pageNumber, pageSize){
  return axios.get(`admin/UserAdmin?pageNumber=${pageNumber}&pageSize=${pageSize}`)
}
function getUsersAdminById (id){
  return axios.get(`admin/UserAdmin?id=${id}&pageNumber=1&pageSize=10`)
}
function getRolesAdmin (){
  return axios.get(`admin/UserAdmin/GetRoles`)
}
function addUserAdmin (body){
  return axios.post(`admin/UserAdmin`, body)
}
function editUserAdmin (body){
  return axios.put(`admin/UserAdmin`, body)
}
function deleteUserAdmin (id){
  return axios.delete(`admin/UserAdmin/${id}`)
}


export {getUsersAdmin, addUserAdmin, editUserAdmin, deleteUserAdmin, getRolesAdmin, getUsersAdminById}