import axios from 'util/Api'


function getReservedName (pageNumber, pageSize){
  return axios.get(`admin/ReservedName?pageNumber=${pageNumber}&pageSize=${pageSize}`)
}
function addReservedName (body){
  return axios.post(`admin/ReservedName`, body)
}
function editReservedName (body){
  return axios.put(`admin/ReservedName`, body)
}
function deleteReservedName (id){
  return axios.delete(`admin/ReservedName/${id}`)
}

export {
  getReservedName,
  addReservedName,
  editReservedName,
  deleteReservedName
}