import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET
} from "../../constants/ActionTypes";
import axios from 'util/Api'
import {message} from 'antd';

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const userSignUp = ({email, password, name}) => {
  console.log(email, password);
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('requestloginadmin', {
        email: email,
        password: password,
      }
    ).then(({data}) => {
      console.log("data:", data);
      if (data.result) {
        localStorage.setItem("tokenAdminTwinup", JSON.stringify(data.token));
        localStorage.setItem("refreshTokenAdminTwinup", JSON.stringify(data.refreshToken));
        axios.defaults.headers.common['authorization'] = "Bearer " + data.token;
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_TOKEN_SET, payload: data.token.access_token});
        dispatch({type: USER_DATA, payload: data.user});
      } else {
        console.log("payload: data.error", data.error);
        dispatch({type: FETCH_ERROR, payload: "Network Error"});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const userSignIn = ({email, password}) => {  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('requestloginadmin', {
        username: email,
        password: password,
      }
    ).then(({data}) => {
      if (data.data) {
        if(data.data.token ){          
          localStorage.setItem("tokenAdminTwinup", data.data.token);
          localStorage.setItem("refreshTokenAdminTwinup", data.data.refreshToken);
          axios.defaults.headers.common['authorization'] = "Bearer " + data.data.token;
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: USER_TOKEN_SET, payload: data.data.token});
        }else if(data.header && data.header.responseCode === 0){
          dispatch({type: USER_TOKEN_SET, payload: {code:data.data}});
          dispatch({type: FETCH_SUCCESS});
        }else{
          message.warning(data.header.message)
          dispatch({type: FETCH_SUCCESS});
        }
        
      } else {
        message.warning(data.header.message)
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_SUCCESS});
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};
export const userSignInActive = ({code, token}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('loginadmin', {
        code: code,
        token: token,
      }
    ).then(({data}) => {
      console.log("userSignIn: ", data);
      if (data.data) {
          localStorage.setItem("tokenAdminTwinup", data.data.token);
          localStorage.setItem("refreshTokenAdminTwinup", data.data.refreshToken);
          axios.defaults.headers.common['authorization'] = "Bearer " + data.data.token;
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: USER_TOKEN_SET, payload: data.data.token});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const getUser = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.defaults.headers.common['authorization'] = "Bearer " + localStorage.getItem('tokenAdminTwinup');
    axios.get('admin/Profile/GetProfile',
    ).then(({data}) => {
      console.log("userSignIn: ", data);
      if (data.data && data.data.id) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_DATA, payload: data.data});
        dispatch({type: USER_TOKEN_SET, payload: localStorage.getItem('tokenAdminTwinup')});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR});
      dispatch({type: USER_TOKEN_SET, payload: false});
      console.log("Error****:", error.message);
    });
  }
};


export const userSignOut = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post(`logout?refreshToken=${localStorage.getItem('refreshTokenAdminTwinup')}`).then(({data}) => {
      if (data && data.header && data.header.responseCode === 0) {
        localStorage.removeItem("tokenAdminTwinup");
        localStorage.removeItem("refreshTokenAdminTwinup");
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SIGNOUT_USER_SUCCESS});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};
