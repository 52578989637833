import React from "react";
import {useSelector} from "react-redux";
import {Menu, Icon} from "antd";
import {Link} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { FundOutlined, SafetyOutlined, DeploymentUnitOutlined, ForkOutlined,
  AimOutlined, ApartmentOutlined, KeyOutlined, FontColorsOutlined
} from '@ant-design/icons';

const SidebarContent = () => {

  let {navStyle, themeType, pathname} = useSelector(({settings}) => settings);

  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];

  return (<>

      <SidebarLogo/>
      <div className="gx-sidebar-content">
        <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
          <UserProfile/>
          {/* <AppsNavigation/> */}
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar-menu">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline">
              <Menu.Item key="dashboard">
              <Link to="/dashboard"><i className="icon icon-widgets"/>
                <IntlMessages id="sidebar.dashboard"/></Link>
            </Menu.Item>
            <Menu.Item key="uers">
              <Link to="/users"><Icon type="team" className="icon"/>
                <IntlMessages id="sidebar.users"/></Link>
            </Menu.Item>
            <Menu.Item key="license">
              <Link to="/license"><KeyOutlined  className="icon"/>
                <IntlMessages id="sidebar.licenseTypes"/></Link>
            </Menu.Item>
            <Menu.Item key="facilities">
              <Link to="/facilities"><i className="icon icon-company"/>
                <IntlMessages id="sidebar.facilities"/></Link>
            </Menu.Item>
            <Menu.Item key="facilityTypes">
              <Link to="/facilityTypes"><Icon type="bank" className="icon"/>
                <IntlMessages id="sidebar.manageFacilityTypes"/></Link>
            </Menu.Item>
            <Menu.Item key="hubs">
              <Link to="/hubs"><DeploymentUnitOutlined className="icon"/>
                <IntlMessages id="sidebar.hubs"/></Link>
            </Menu.Item>
            <Menu.Item key="endnodes">
              <Link to="/endnodes"><ForkOutlined className="icon"/>
                <IntlMessages id="sidebar.endnodes"/></Link>
            </Menu.Item>
            <Menu.Item key="specification">
              <Link to="/specification"><SafetyOutlined className="icon"/>
                <IntlMessages id="sidebar.specification"/></Link>
            </Menu.Item>
            
            
            <Menu.Item key="semantics">
              <Link to="/semantics"><i className="icon icon-basic-calendar"/>
                <IntlMessages id="sidebar.manageSemantics"/></Link>
            </Menu.Item>            
            <Menu.Item key="semanticUnits">
              <Link to="/semanticUnits"><Icon type="calculator" className="icon"/>
                <IntlMessages id="sidebar.manageSemanticUnits"/></Link>
            </Menu.Item>  
            <Menu.Item key="bacnetObjectType">
              <Link to="/bacnetobjecttype"><ApartmentOutlined className="icon"/>
                <IntlMessages id="sidebar.bacnetObjectType"/></Link>
            </Menu.Item> 
            <Menu.Item key="insights">
              <Link to="/insights"><FundOutlined className="icon"/>
                <IntlMessages id="sidebar.insights"/></Link>
            </Menu.Item>
            <Menu.Item key="icons">
              <Link to="/icons"><AimOutlined className="icon"/>
                <IntlMessages id="sidebar.icons"/></Link>
            </Menu.Item> 
            <Menu.Item key="reservedName">
              <Link to="/reservedname"><FontColorsOutlined className="icon"/>
                <IntlMessages id="sidebar.reservedName"/></Link>
            </Menu.Item>    
            <Menu.Item key="superAdmin">
              <Link to="/superAdmin"><i className="icon icon-profile"/>
                <IntlMessages id="sidebar.admins"/></Link>
            </Menu.Item>                                                                                      
            <Menu.Item key="rawmessage">
              <Link to="/rawmessage"><i className="icon icon-message"/>
                <IntlMessages id="sidebar.rawmessage"/></Link>
            </Menu.Item>
            
            
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};

export default SidebarContent;

