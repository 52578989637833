import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Popover } from "antd";
import { userSignOut, getUser } from "../../appRedux/actions/Auth";
import { getProfile, BASE_IMG } from 'services';
import { Link } from 'react-router-dom';
import IntlMessages from "util/IntlMessages";

const UserProfile = () => {
  const [user, setUser] = useState(false);
  const auth = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <Link to="/profile"><li><IntlMessages id="btn.myAccount" /></li></Link>
      <li onClick={() => dispatch(userSignOut())}><IntlMessages id="btn.signout" />
      </li>
    </ul>
  );
  useEffect(() => {
    dispatch(getUser());

  }, [])
  useEffect(() => {
    setUser(auth.authUser)
  }, [auth.authUser])
  return (

    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
        <div style={{ display: "flex" }}>
          <Avatar style={{ backgroundColor: "#038fde" }} src={user && (BASE_IMG + user.avatar)}
            className="gx-size-40 gx-pointer gx-mr-3" alt="">
            {user && user.firstName.charAt(0).toUpperCase() + (user && user.lastName.charAt(0).toUpperCase())}
          </Avatar>
          <div className="gx-avatar-name" style={{ flex: 1, lineHeight: "40px", width: "120px", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>{user && user.firstName + " " + user.lastName}
          </div><i
            style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            className="icon icon-chevron-down gx-fs-xxs gx-ml-2" /></div>

      </Popover>
    </div>

  )
};

export default UserProfile;
