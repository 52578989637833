import axios from 'util/Api'


function getEndNodeLibrariesSpecification (id, pageNumber, pageSize){
  return axios.get(`admin/EndNodeLibrarySpecification?LibraryId=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}`)
}
function addEndNodeLibrarySpecification (body){
  return axios.post(`admin/EndNodeLibrarySpecification`, body)
}
function editEndNodeLibrarySpecification (body){
  return axios.put(`admin/EndNodeLibrarySpecification`, body)
}
function deleteEndNodeLibrarySpecification(id){
  return axios.delete(`admin/EndNodeLibrarySpecification/${id}`)
}

export {getEndNodeLibrariesSpecification, addEndNodeLibrarySpecification, editEndNodeLibrarySpecification, deleteEndNodeLibrarySpecification}