import axios from 'util/Api'


function getUsers (search,pageNumber, pageSize){
  return axios.get(`admin/User?search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}`)
}
function loginUser (id){
  return axios.post(`admin/User/Login?userId=${id}`)
}
function editUser (body){
  return axios.put(`admin/User`, body)
}
function deleteUser (id){
  return axios.delete(`admin/User/${id}`)
}


export {getUsers, loginUser, editUser, deleteUser}