import axios from 'util/Api'


function getBacnetObjectType (params){
  return axios.get(`admin/BacnetObjectType`,{params: params})
}
function addBacnetObjectType (body){
  return axios.post(`admin/BacnetObjectType`, body)
}
function editBacnetObjectType (body){
  return axios.put(`admin/BacnetObjectType`, body)
}
function deleteBacnetObjectType (id){
  return axios.delete(`admin/BacnetObjectType/${id}`)
}

export {getBacnetObjectType, addBacnetObjectType, editBacnetObjectType, deleteBacnetObjectType}