import axios from 'util/Api'


function getFileIcons (pageNumber, pageSize){
  return axios.get(`admin/FileIcon?pageNumber=${pageNumber}&pageSize=${pageSize}`)
}
function addFileIcon (body){
  return axios.post(`admin/FileIcon`, null,{params: body})
}
function editFileIcon (body){
  return axios.put(`admin/FileIcon`, null,{params: body})
}
function deleteFileIcon (id){
  return axios.delete(`admin/FileIcon/${id}`)
}
function uploadFileIcon (body,id){
  return axios.post(`admin/FileIcon/Upload`, body, {params:{
    id: id
  }})
}

export {getFileIcons, addFileIcon, editFileIcon, deleteFileIcon, uploadFileIcon}