import axios from 'util/Api'


function getInsightLibraryRequirement (id, pageNumber, pageSize){
  return axios.get(`admin/InsightLibraryRequirement?insightLibraryId=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}`)
}
function addInsightLibraryRequirement (body){
  return axios.post(`admin/InsightLibraryRequirement`, body)
}
function editInsightLibraryRequirement (body){
  return axios.put(`admin/InsightLibraryRequirement`, body)
}
function deleteInsightLibraryRequirement (id){
  return axios.delete(`admin/InsightLibraryRequirement/${id}`)
}

export {getInsightLibraryRequirement, addInsightLibraryRequirement, editInsightLibraryRequirement, deleteInsightLibraryRequirement}