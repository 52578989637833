import axios from 'util/Api'


function getFacilityTypes (pageNumber, pageSize){
  return axios.get(`admin/FacilityType?pageNumber=${pageNumber}&pageSize=${pageSize}`)
}
function addFacilityType (body){
  return axios.post(`admin/FacilityType`, body)
}
function editFacilityType (body){
  return axios.put(`admin/FacilityType`, body)
}
function deleteFacilityType (id){
  return axios.delete(`admin/FacilityType/${id}`)
}

export {getFacilityTypes, addFacilityType, editFacilityType, deleteFacilityType}