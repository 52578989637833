import axios from 'util/Api'


function getLicenses (pageNumber, pageSize){
  return axios.get(`admin/License?pageNumber=${pageNumber}&pageSize=${pageSize}`)
}
function getLicensesById (id,pageNumber, pageSize){
  return axios.get(`admin/License?id=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}`)
}
function addLicense (body){
  return axios.post(`admin/License`, body)
}
function editLicense (body){
  return axios.put(`admin/License`, body)
}
function deleteLicense (id){
  return axios.delete(`admin/License/${id}`)
}

export {getLicenses, addLicense, editLicense, deleteLicense, getLicensesById}