import axios from 'util/Api'


function getFacilities (search, pageNumber, pageSize){
  return axios.get(`admin/Facility?search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}`)
}
function getFacilityLicense (facilityId){
  return axios.get(`admin/Facility/GetFacilityLicenses?facilityId=${facilityId}`)
}
function editFacility (body){
  return axios.put(`admin/Facility/AddLicense`, body)
}


export {getFacilities, editFacility, getFacilityLicense}