import React,{useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut, getUser} from "appRedux/actions/Auth";
import {Link} from 'react-router-dom';
import IntlMessages from "util/IntlMessages";

const UserInfo = () => {
  const [user, setUser] = useState(false);
  const auth = useSelector(({auth}) => auth);  
  const dispatch = useDispatch();
  useEffect(()=>{
    setUser(auth.authUser)
  },[auth.authUser])
  const userMenuOptions = (
    <ul className="gx-user-popover">
        <Link to="/profile"><li><IntlMessages id="btn.myAccount"/></li></Link>        
        <li onClick={() => dispatch(userSignOut())}><IntlMessages id="btn.signout"/>
      </li>
    </ul>
  );

  return (
    <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
             trigger="click">
      <Avatar style={{backgroundColor:"#038fde"}}
                className="gx-size-40 gx-pointer gx-mr-3" alt="">
          <span style={{color:"#fff"}}>{user && user.firstName.charAt(0).toUpperCase()+( user && user.lastName.charAt(0).toUpperCase())}</span>
        </Avatar>
    </Popover>
  )
};

export default UserInfo;
