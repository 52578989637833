import axios from 'util/Api'


function getHubs (body){
  return axios.post(`admin/FacilityDevice/GetList`, body)
}
function deleteToggleHub (params){
  return axios.put(`admin/FacilityDevice/IsDeleteToggle`,null,{
    params
  } )
}
function changeFacilityHub (params){
  return axios.put(`/admin/FacilityDevice/ChangeFacility`, null, {
    params
  })
}


export {getHubs, deleteToggleHub, changeFacilityHub}