import axios from 'util/Api'


function getDeviceEndNodes (body){
  return axios.post(`admin/FacilityDeviceEndNode/GetList`, body)
}
function deleteToggleDeviceEndnode (params){
  return axios.put(`admin/FacilityDeviceEndNode/IsDeleteToggle`,null,{
    params
  } )
}
function changeDeviceEndnode (params){
  return axios.put(`/admin/FacilityDeviceEndNode/ChangeDevice`, null, {
    params
  })
}


export {getDeviceEndNodes, deleteToggleDeviceEndnode, changeDeviceEndnode}