import axios from 'util/Api'


function getSemantics (pageNumber, pageSize){
  return axios.get(`admin/Semantic?pageNumber=${pageNumber}&pageSize=${pageSize}`)
}
function addSemantic (body){
  return axios.post(`admin/Semantic`, body)
}
function editSemantic (body){
  return axios.put(`admin/Semantic`, body)
}
function deleteSemantic (id){
  return axios.delete(`admin/Semantic/${id}`)
}

export {getSemantics, addSemantic, editSemantic, deleteSemantic}