import axios from 'util/Api'


function getProfile (){
  return axios.get(`admin/Profile/GetProfile`)
}
function getSetting (){
  return axios.get(`admin/Profile/GetSetting`)
}
function getAvatar (){
  return axios.get(`admin/Profile/GetAvatar`)
}
function saveProfile (body){
  return axios.post(`admin/Profile/SaveProfile`, body)
}
function saveSetting (body){
  return axios.post(`admin/Profile/SaveSetting`, body)
}
function changePassword (body){
  return axios.post(`admin/Profile/ChangePassword`, body)
}
function uploadAvatar (body){
  return axios.post(`admin/Profile/UploadAvatar`, body)
}
function deleteAvatar (){
  return axios.delete(`admin/Profile/RemoveAvatar`)
}
function logOut (){
  return axios.post(`logout?refreshToken=${localStorage.getItem('refreshTokenAdminTwinup')}`)
}

function getDashboard(){
  return axios.get(`admin/Dashboard`)
}
export {getProfile, getSetting, getAvatar, 
  saveProfile, saveSetting,
  changePassword,
  uploadAvatar, deleteAvatar, logOut, getDashboard}