import axios from 'util/Api'


function getSemanticUnits (pageNumber, pageSize){
  return axios.get(`admin/SemanticUnit?pageNumber=${pageNumber}&pageSize=${pageSize}`)
}
function addSemanticUnit (body){
  return axios.post(`admin/SemanticUnit`, body)
}
function editSemanticUnit (body){
  return axios.put(`admin/SemanticUnit`, body)
}
function deleteSemanticUnit (id){
  return axios.delete(`admin/SemanticUnit/${id}`)
}

export {getSemanticUnits, addSemanticUnit, editSemanticUnit, deleteSemanticUnit}