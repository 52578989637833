import axios from 'util/Api'


function getEndNodeLibraries (pageNumber, pageSize){
  return axios.get(`admin/EndNodeLibrary?pageNumber=${pageNumber}&pageSize=${pageSize}`)
}
function addEndNodeLibrary (body){
  return axios.post(`admin/EndNodeLibrary`, body)
}
function editEndNodeLibrary (body){
  return axios.put(`admin/EndNodeLibrary`, body)
}
function deleteEndNodeLibrary (id){
  return axios.delete(`admin/EndNodeLibrary/${id}`)
}

export {getEndNodeLibraries, addEndNodeLibrary, editEndNodeLibrary, deleteEndNodeLibrary}