import React, {useEffect} from "react";
import {Button, Form, Input} from "antd";
import {useDispatch, useSelector} from "react-redux";
// import {Link} from "react-router-dom";

import {userSignIn, userSignInActive} from "../appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";

const FormItem = Form.Item;

const SignIn = (props) => {
  const dispatch = useDispatch();
  const token = useSelector(({auth}) => auth.token);
  const [openActiveCode, setOpenActiveCode] = React.useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        dispatch(userSignIn(values));
      }
    });
  };
  const handleSubmitActiveCode = (e) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        dispatch(userSignInActive({
          token: token.code,
          code: values.code
        }));
      }
    });
  };

  useEffect(() => {
    if (token) {      
      // props.history.push('/');
    }    
  },[token]);

  const {getFieldDecorator} = props.form;

  return (
    <div className="gx-app-login-wrap">
      
    </div>
  );
};

const WrappedNormalLoginForm = Form.create()(SignIn);

export default WrappedNormalLoginForm;
